import React from 'react'
	
function TodoItem() {
	return (
		<div>
			<input type="checkbox" />
			<p>hola</p>
		</div>
	)
}

export default TodoItem
