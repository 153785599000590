import React from 'react'
//import Navbar from './components/Navbar'
//import MainContent from './components/MainContent'
import TopBar from './topbar/TopBar'
import TodoItem from './components/TodoItem'

function App() {
	//const firstName = "Steve";
	return (
		<div>
		<TopBar />
		<TodoItem />
		<TodoItem />
		<TodoItem />
		<TodoItem />
		<TodoItem />
		</div>
	)
}

export default App
