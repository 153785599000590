import React from 'react';
import './topbar.css'

export default function TopBar() {
    return (
		<div className='top'>
                    TopBar
		</div>
        )
    }

